import { render, staticRenderFns } from "./Particles.html?vue&type=template&id=73713c50&scoped=true&"
import script from "./Particles.js?vue&type=script&lang=js&"
export * from "./Particles.js?vue&type=script&lang=js&"
import style0 from "./Particles.scss?vue&type=style&index=0&id=73713c50&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73713c50",
  null
  
)

export default component.exports