export default {
    name: "NavbarComponent",
    data () {
        return {
            name: 'about',
        }
    },
    methods: {
    }
}
