export default {
  name: "PresentationComponent",
  data() {
    return {
      typingText: [
        "Web Developer",
        "Front End",
        "Management",
        "UI Designer",
        "Technical Writer",
        "Cloud Computing",
        "Back End",
        "Project management",
      ]
    }
  },
}
