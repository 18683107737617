<template>
  <div id='accueil'>
    <ParticlesComponent></ParticlesComponent>
    <NavbarComponent></NavbarComponent>

    <div class="ct" id="home">
      <div class="ct" id="about">
        <div class="ct" id="projets">
          <div class="ct" id="contact">

              <section class="page" id="homeSection">
                <PresentationComponent></PresentationComponent>
              </section>

              <section class="page" id="aboutSection">
                <AboutComponent></AboutComponent>
              </section>

              <section class="page" id="projetsSection">
                <ProjetsComponent></ProjetsComponent>
              </section>

              <section class="page" id="contactSection">
                <ContactComponent></ContactComponent>
              </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ParticlesComponent from "@/components/particles/Particles.vue";
import NavbarComponent from "@/components/navbar/Navbar.vue";
import PresentationComponent from "@/components/presentation/Presentation.vue";
import AboutComponent from "@/components/about/About.vue";
import ContactComponent from "@/components/contact/Contact.vue";
import ProjetsComponent from "@/components/projets/Projets.vue";

export default {
  name: 'App',
  components: {
    ParticlesComponent,
    NavbarComponent,
    PresentationComponent,
    AboutComponent,
    ContactComponent,
    ProjetsComponent,
  },
  metaInfo: {
    title: 'Default App Title',
    titleTemplate: '%s | vue-meta Example App'
  },
};

</script>
<style lang="scss" src="./App.scss"></style>
