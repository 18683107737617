// Import
import Vue from 'vue';
import App from './App.vue';
import Particles from "particles.vue";
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueTypedJs from 'vue-typed-js';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { NavbarPlugin } from 'bootstrap-vue';


// Config
Vue.use(Particles);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(NavbarPlugin);
Vue.use(VueTypedJs);

// Mode prod
Vue.config.productionTip = false;

// Vue
new Vue({
  render: h => h(App),
}).$mount('#app')
