import projects from '../../mocks/projets.json'

export default {
  name: "ProjetsComponent",
  data() {
    return {
      configCarousel: {
        //Basic Speeds
        slideSpeed : 200,
        paginationSpeed : 800,

        //Autoplay
        autoPlay : false,
        goToFirst : true,
        goToFirstSpeed : 1000,

        // Navigation
        nav : false,

        // Responsive
        items : 3,
        margin:5,
        responsiveClass:true,
        responsive:{
          0:{
            items:1,
          },
          600:{
            items:2,
          },
          1000:{
            items:2,
          },
          1200:{
            items:3,
          }
        }
      },
      projectsJson: projects,
      selectedTags: [],
      tag: [],
    }
  },
  beforeMount() {
    this.findTag();
    this.projectsJson.sort((a, b) => {
      if (a.date > b.date) {
        return -1;
      } else if (a.date < b.date) {
        return 1;
      } else {
         return 0;
        }
    });
  },
  methods: {
    imgSrc(images) {
      return require(`../../assets/projets/${images}`);
    },
    findTag() {
      this.tag = [];
      projects.forEach(p => {
        if (p && p.tag && p.tag.length !== 0) {
          this.tag = this.tag.concat.apply(this.tag, p.tag);
        }
      });
      this.tag = [... new Set(this.tag)].sort()
      return this.tag;
    },
    filterByTags(v) {
      // Cherche l'index du tag
      const index = this.selectedTags.indexOf(v);

      if (index > -1) {
        // Si le tag est trouver ont le supprime
        this.selectedTags.splice(index, 1);
      } else {
        // Sinon on l'ajoute au tableau
        this.selectedTags.push(v);
        // On le tri et on vérifie qu'il n'est pas en double
        this.selectedTags = [... new Set(this.selectedTags)].sort();
      }

      // New
      let newTag = [];

      if (this.selectedTags.length !== 0) {
        projects.forEach(t => {
          if (this.matched(t.tag, this.selectedTags)) {
            newTag.push(t);
          }
        });
      } else {
        newTag = projects;
      }

      this.projectsJson = newTag;
    },
    matched(tag, select) {
      if (select.length === 0) {
        return true;
      } else {
        return tag.some(v => select.includes(v))
      }
    },
    clearFilter() {
      this.selectedTags = [];
      this.projectsJson = projects;
    }
  }
}

